// extracted by mini-css-extract-plugin
export var bottom = "footer-module--bottom--22a1c";
export var container = "footer-module--container--b921d";
export var erasmusLogoWrapper = "footer-module--erasmusLogoWrapper--48dcd";
export var footer = "footer-module--footer--02543";
export var footerSocialNetworks = "footer-module--footerSocialNetworks--a3115";
export var greyBackground = "footer-module--greyBackground--d950b";
export var iconText = "footer-module--iconText--92eca";
export var logo = "footer-module--logo--c7477";
export var main = "footer-module--main--7567f";
export var navLink = "footer-module--navLink--eabd3";
export var navigation = "footer-module--navigation--0d24f";
export var whiteBackground = "footer-module--whiteBackground--66566";