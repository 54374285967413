import React from "react";
import { useState } from "react";

import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button/Button";
import { useIntl } from "gatsby-plugin-intl";

import CvcLoadingProgress from "ui/CvcLoadingProgress";
import CvcTransferList from "ui/CvcTransferList";
import repositoryService from "services/api/repositoryService";
import { handleError } from "utils/error/error-utils";
import { useEffect } from "react";
import CvcConfirmActionDialog from "ui/CvcConfirmActionDialog";
import { containsRole } from "services/auth";
import { ROLE } from "utils/constants/constants";

const DeleteReferenceDatasTab = ({ 
    userConnected,
    selectedReferenceTable, 
    showErrorMessage, 
    showSuccessMessage 
}) => {

    const intl = useIntl();
    const [loadingDatas, setLoadingDatas] = useState(false);
    const [reloadDatas, setReloadDatas] = useState(true); 
    const [referenceDataList, setReferenceDataList] = useState([]);
    
    const [selectedData, setSelectedData] = useState([]);    
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [disabledValidateBtn, setDisabledValidateBtn]= useState(true);    
    
    const showActions = {
        showReloadData: true,
        showMoveAllRight: true,
        showMoveAllLeft: true,
        showMoveSelectedRight: true,
        showMoveSelectedLeft: true
    }

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setReloadDatas(true);
        }      
        return () => { mounted=false; };
    }, [selectedReferenceTable]);


    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingDatas(true);
            try {
                const findReferenceDataByListCodeDto = {
                    userConnected: containsRole([ROLE.academics_manager, ROLE.admin_university]) ? userConnected : null,
                    listCode: selectedReferenceTable.value
                }  
                const result = await repositoryService.findReferenceDataNotUsedByListCode(findReferenceDataByListCodeDto);
                if(mounted){
                    const referenceDataList = result.data.map(item => { 
                        const universityLabel = item.universityAcronym ? ` - ${item.universityAcronym} `: ' ';
                        item.label = `${item.label}${universityLabel}(${item.count})`;
                        return item;
                     });
                    setReferenceDataList(referenceDataList);
                    setSelectedData([]);                                                      
                }                                
            } catch (error) {
                handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.fetchValues.error'}), 
                    showErrorMessage
                );
            } finally {                
                setLoadingDatas(false);
                setReloadDatas(false);
            }
        };
        if(reloadDatas && !loadingDatas) fetchData();        
        return () => { mounted=false; };
    }, [reloadDatas]);

    const handleSelectedReferenceDataChange = (selectedItems) => {
        setSelectedData(selectedItems);
        if(selectedItems && selectedItems.length > 0){
            setDisabledValidateBtn(false);
        } else {
            setDisabledValidateBtn(true);
        }       
    }

    const handleReloadReferenceDataList = () => {
        setSelectedData([]);
        setReloadDatas(true);
    }

    const handleClickValidateStandardization = async () => {
        setOpenConfirmDialog(true);
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        setOpenConfirmDialog(false);
        handleDeleteReferencesDatas();
    }

    const handleDeleteReferencesDatas = async () => {
        try {
            const deleteReferenceDataDto = {
                listCode: selectedReferenceTable.value,
                referenceValuesToDelete: selectedData.map(data => data.id),
            }                                            
            await repositoryService.deleteReferenceData(deleteReferenceDataDto);
            showSuccessMessage(intl.formatMessage({ id: 'backoffice.repositories.deletion.delete.success'}));            
            setReloadDatas(true);     
        } catch (error) {
            handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.deletion.delete.error'}), 
                    showErrorMessage
                );
        }
    }

    return (<>
        { loadingDatas &&  <CvcLoadingProgress /> }
        { !loadingDatas  &&
            <Grid container alignItems='center' rowSpacing={2} sx={{ width: '100%', height: '100%' }}>                
                <Grid item xs={12} sm={10}>
                    
                    <Grid container marginTop={'20px'} columnSpacing={11}>
                        <Grid item xs={6}>
                            <Typography component="p" >
                                {   intl.formatMessage(
                                    { id: "backoffice.repositories.deletion.transferlist.left.title" }, 
                                    { values:  selectedReferenceTable.label})
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="p">
                                {   intl.formatMessage(
                                    { id: "backoffice.repositories.deletion.transferlist.right.title" }, 
                                    { values:  selectedReferenceTable.label})
                                }
                            </Typography>
                        </Grid>
                    </Grid> 
                    
                    <CvcTransferList 
                        filterLabel={intl.formatMessage({ id: "backoffice.repositories.deletion.transferlist.filter.label" })}
                        showActions={showActions}
                        items={referenceDataList}
                        selectedItems={selectedData}
                        onChange={handleSelectedReferenceDataChange}
                        showReloadData={true}
                        onReloadData={handleReloadReferenceDataList}                
                        listHeight={440}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <Button 
                        variant='contained' 
                        disabled={disabledValidateBtn} 
                        onClick={handleClickValidateStandardization}
                    >
                        {intl.formatMessage({ id: "backoffice.repositories.deletion.btn.delete" })}
                    </Button>
                    <CvcConfirmActionDialog 
                        open={openConfirmDialog} 
                        title={intl.formatMessage({id: "backoffice.repositories.deletion.dialog.confirmDelete.title"})} 
                        contentText={intl.formatMessage({id: "backoffice.repositories.deletion.dialog.confirmDelete.contentText"})} 
                        onClose={handleCloseConfirmDialog} 
                        cancelBtnLabel={intl.formatMessage({id: "backoffice.repositories.deletion.dialog.confirmDelete.btn.cancel"})} 
                        onCancel={handleCloseConfirmDialog} 
                        actionBtnLabel={intl.formatMessage({id: "backoffice.repositories.deletion.dialog.confirmDelete.btn.delete"})} 
                        onAction={handleConfirmAction}  
                    />
                </Grid>
            </Grid>
        } 
    </>);
}


export default DeleteReferenceDatasTab;