import React, { useCallback, useEffect } from "react";

import { useMediaQuery, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Button, Grid, Divider } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useIntl } from "gatsby-plugin-intl";
import {useForm} from "react-hook-form";

import {GROUP} from "utils/constants/back-office-constants";
import { ROLE } from "utils/constants/constants";
import CvcTabPanel from "ui/CvcTabPanel";

import userService from "services/api/userService";
import courseService from "services/api/courseService";
import { containsRole, getUniversityId } from "services/auth";

import { RhfListOfValuesSelect, RhfRadioButtonAllRow, RhfTextField } from "components/backoffice/common/RhfFields";
import Seo from "components/common/Seo";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import CustomDataGrid from "components/backoffice/common/CustomDataGrid";
import RhfAutoCompleteMonoValue from "ui/RhfAutoCompleteMonoValue";
import RhfListOfValuesCombo from "ui/RhfListOfValuesCombo";
import { getCourseLabel } from "components/catalog/common/CourseUtils";
import CustomSnackbar from "../common/CustomSnackbar";
import { downloadCSV, convertToCSV } from "utils/export-utils";
import academicService from "services/api/academicService";

import ExportReferenceDataTab from "components/backoffice/repositories/tabs/ExportReferenceDataTab";


const LOCAL_STORAGE_SEARCH_CRITERIA = "backoffice.users.searchCriteria";

const styles = {
    filter: {
        title: {
            fontSize: "1rem",
            fontWeight: 400,
            color: "inherit",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            lineHeight: 1.5
        },
        accordion: {
            paddingTop: 10,
            marginTop: 10
        },
        accordionSummary: {
            minHeight: 20, 
            maxHeight: 20,
            margin: "10px 20px 0px",
            padding: "0px",
            //border: "1px solid green"
        },
        accordionDetails: {
            margin: "0px 20px 0px",
            padding: "0px",
            //border: "1px solid green"
        },
        gridContainer: {
            rowSpacing: 0,
            columnSpacing: 0,
            style: {
                margin: "0px",
                padding: "0px 0px 20px",
                //border: "1px solid red"
            }    
        },
        accordionActions: {
            justifyContent: "center",
            //border: "1px solid red"
        },
        fullText: {
            width: "100%",
            padding: "0px", margin: "0px",
            justifyContent: "center",
        }, 
        institution: {
            mobile: {
                width: "100%",
                paddingRight: "0px"
            },
            desktop: {
                width: "100%",
                paddingRight: "20px"
            }    
        }, 
        userRoleGroup: {
            width: "100%",
        }, 
        connected: {
            marginTop: "20px",
        }, 
        enrolled: {
            marginTop: "20px",
        }, 
        course: {
            width: "100%",
            paddingTop: "30px",
        }
    }
};


const UserListPage = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const defaultFilterValues = {
        fullText: "",
        universityId: "",
        roleGroupName: "",
        isAlreadyConnected: "",
        isEnrolledInCourse: "",
        courseId: ""
    }

    const [disabledSubmit, setDisabledSubmit] = React.useState(false);

    const handleExport = async () => {
        setDisabledSubmit(true);
        setDisabledExport(true);
        setLoading(true);        
        try {
            const response = await academicService.exportAcademicsByCriteria(searchCriteria.current)        
            const data = await convertToCSV(response.data);
            await downloadCSV(data, 'userList');
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);
        } catch(error) {
            console.error("error exporting userList", error);
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);
        };
    };

    const intl = useIntl();
    const useFormMethods = useForm({
        defaultValues: defaultFilterValues
    });
    const searchCriteria = React.useRef({});
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [courses, setCourses] = React.useState([]);
    const [showCourses, setShowCourses] = React.useState(false);


    const pageSize = 50;
    const [totalNumberOfRow, setTotalNumberOfRow] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [page, setPage] = React.useState(0);

    const [errorMessage, setMessage] = React.useState("");
    const [openError, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [autoHideDuration, setAutoHideDuration] = React.useState(6000);



    const CELL_NOT_VISIBLE = "     -     ";
    const columns = [

        {
            key: 1,
            flex: 1,
            field: "lastName",
            sortable: true,
            filterable: false,
            headerName: intl.formatMessage({id: "backoffice.users.columns.lastName"}),
            renderCell: (field) => {
                return ''+field.value;
            }
        },
        {
            key: 2,
            flex: 1,
            field: "firstName",
            sortable: true,
            filterable: false,
            headerName: intl.formatMessage({id: "backoffice.users.columns.firstName"}),
            renderCell: (field) => {
                return ''+field.value;
            }
        },
        {
            key: 3,
            flex: 1,
            field: "universityName",
            sortable: true,
            filterable: false,
            headerName:  intl.formatMessage({id: "backoffice.users.columns.institution"}),
            renderCell: (field) => {
                return field.value ? field.value : "";
            }
        },
        {
            flex: 1,
            field: "userRoleGroups",
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({id: "backoffice.users.columns.userRoleGroups"}),
            renderCell: (field) => {
                return ''+field.value;
            }
        },
        {
            key: 4,
            flex: 1,
            field: "email",
            sortable: true,
            filterable: false,
            headerName: intl.formatMessage({id: "backoffice.users.columns.email"}),
            renderCell: (field) => {
                return ''+field.value;
            }
        },
        {
            key: 5,
            flex: 1,
            field: "lastConnectionDate",
            sortable: true,
            filterable: false,
            headerName: intl.formatMessage({id: "backoffice.users.columns.lastConnectionDate"}),
            renderCell: (field) => {
                if(field.value) {
                    if(field.value == CELL_NOT_VISIBLE){
                        return CELL_NOT_VISIBLE;
                    } else {
                        return new Date(field.value).toLocaleString();
                    }
                } else {
                    return "";
                }
            }
        }
    ];

    const editColumnParams = {
        field: "userId",
        arialLabel: "modify user",
        url: "/back-office/users/details"
    }

    const gdprValue = (rowUniversityId, cellValue) => {
        return getUniversityId() == rowUniversityId  || containsRole([ROLE.admin]) ? cellValue : CELL_NOT_VISIBLE;
    }

    const lower = (string) => {
        return string ? string.toLowerCase() : ""
    }

    const upper = (string) => {
        return string ? string.toUpperCase() : ""
    }

    useEffect(() => {
        let mounted = true;
        const fetchCoursesList = async () => {            
            courseService.findAllCoursesOrderByPeriod()
            .then((results) => {
                if(mounted){
                    let courses = [];
                    if(results?.data){
                        courses = results.data.map((course) => { 
                            return ( { value: course.id, label: getCourseLabel(intl, course) }) 
                        });
                    }
                    setCourses(courses);
                }                
            })
            .catch((error) => {
                console.error("error fetching courses list", error);
            });
        }
        fetchCoursesList(); 
        return () => { mounted = false; }   
    }, []);

    const loadServerRows = useCallback(async (page, sortModel) => {
        
        page??=0;
        sortModel??=[];
        setDisableSubmit(true);
        setLoading(true);

        userService.searchByCriteria(
            searchCriteria.current,
            page,
            pageSize,
            sortModel
        )
        .then((response) => {
            let users = [];
            if(response?.data?.content){                    
                users = response.data.content.map(row => (
                    { ...row, 
                        id: row.userId,
                        key: row.userId,
                        firstName: row.firstName,
                        lastName: upper(row.lastName),
                        userRoleGroups: gdprValue(row.universityId, row.userRoleGroups),
                        email: gdprValue(row.universityId, lower(row.email)),
                        lastConnectionDate: gdprValue(row.universityId, row.lastConnectionDate),
                        deletable: getUniversityId() == row.universityId || containsRole([ROLE.admin]),
                        editable: getUniversityId() == row.universityId || containsRole([ROLE.admin])
                    }
                ));
            }
            setPage(page);
            setTotalNumberOfRow(response.data.totalSize);
            setRows(users);
            setLoading(false);
            setDisableSubmit(false);
        })
        .catch((error) => {
            console.error("error searching users by criteria", error);
            setLoading(false);
            setDisableSubmit(false);
        });        
    }, []);

    useEffect(() => {
        let mounted = true;
        const fetchUsesRows = async () => {  
            let item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_CRITERIA));
            if(item){
                searchCriteria.current = item;
                Object.keys(item).forEach(key => {
                    useFormMethods.setValue(key, item[key]);
                });
            }
            await loadServerRows(0, []);
            if(mounted){
                setRows([]);
            }                        
        }                
        fetchUsesRows();
        return () => { mounted = false; }
    }, [loadServerRows]);

    const submitForm = (formData) => {
        searchCriteria.current = formData;
        localStorage.setItem(LOCAL_STORAGE_SEARCH_CRITERIA, JSON.stringify(searchCriteria.current));
        loadServerRows(0, []);
    };

    const resetForm = () => {
        useFormMethods.reset(defaultFilterValues);
        searchCriteria.current = useFormMethods.getValues();
        localStorage.removeItem(LOCAL_STORAGE_SEARCH_CRITERIA);
        loadServerRows(0, []);
    };

    const watchIsEnrolled = useFormMethods.watch("isEnrolledInCourse", 'Yes');
    useEffect(() => {
        handleIsEnrolledChange();
    }, [watchIsEnrolled]);

    const handleIsEnrolledChange = () => {
        if(watchIsEnrolled == 'True') {
            setShowCourses(true);
        } else {
            setShowCourses(false);
            useFormMethods.setValue("courseId", "");
        } 
    }

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const handleDelete = async (selection) => {
        userService
            .deleteUsers(selection)
            .then((result) => {
                setMessage(intl.formatMessage({id: "backoffice.users.delete.success"}));
                setSeverity("success");
                setAutoHideDuration(6000);
                setOpen(true);
                loadServerRows();
                setSelectionModel([]);
            })
            .catch((error) => {
                handleDeleteErrorMessage(error);
                loadServerRows();
                setSelectionModel([]);
            });
    };


    // ici 
    const showExportButton = containsRole([ROLE.admin, ROLE.admin_university]);
    const [disabledExport, setDisabledExport] = React.useState(false); 
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);


    /**
     * handle error message :
     * - first string which is the list of user's name who can't be deleted
     * - second string which is the list of user's name who were deleted successfully
     *
     * @param usersInError users which can't be deleted
     * @param usersDeleted users deleted successfully
     * @return parameters list of these two strings
     */


    

    const handleDeleteErrorMessage = (error) => {
        const parameters = error?.data?.parameters;
        let errorMessage = "";
        if (parameters?.usersInError?.length > 0) {
            let usersInErrorNames = parameters.usersInError.map( user => user.firstName + ' ' + user.lastName).join(', ');
            usersInErrorNames += '.';
            errorMessage = intl.formatMessage({id: "backoffice.users.delete.warning.error"}, {errors: usersInErrorNames});
            setSeverity("error");
        }

        let successMessage = "";
        if (parameters?.usersDeletedSuccessfully?.length > 0) {
            let successDeletedUserNames = parameters.usersDeletedSuccessfully.map( user => user.firstName + ' ' + user.lastName).join(', ');
            successDeletedUserNames += '.';
            successMessage = intl.formatMessage({id: "backoffice.users.delete.warning.success"}, {success: successDeletedUserNames});
            setSeverity("warning");
        }

        let globalMessage = errorMessage + "\n\n" + successMessage;
        setMessage(globalMessage);
        setAutoHideDuration(14000);
        setOpen(true);
    }


    return (
        <>
            <LayoutBo>
                <Seo title={intl.formatMessage({id: "backoffice.users.title"})} lang={intl.locale} />

                        <CustomSnackbar
                            severity={severity}
                            position={{ vertical: 'top', horizontal: 'center' }}
                            open={openError}
                            setOpen={setOpen}
                            messageStr={errorMessage}
                            autoHideDuration={autoHideDuration}
                        />

                        <form onSubmit={useFormMethods.handleSubmit(submitForm)} noValidate>
                        <Accordion defaultExpanded style={ styles.filter.accordion } >

                            <AccordionSummary  id="user-filter-header" expandIcon={<ExpandMoreIcon/>} 
                                        style={ styles.filter.accordionSummary } >
                                <h1 style={ styles.filter.title }>
                                    {intl.formatMessage({id: "backoffice.filter.title"})}
                                </h1>
                            </AccordionSummary>                                      
                            <AccordionDetails id="user-filter-criteria"
                                        style={ styles.filter.accordionDetails } >
                            
                                <Grid container columnSpacing={ styles.filter.gridContainer.columnSpacing }
                                                rowSpacing={ styles.filter.gridContainer.rowSpacing } 
                                                style={ styles.filter.gridContainer.style }>
                                    <Grid item xs={12} sm={12}>
                                        <RhfTextField id="filter-fullText"
                                            name="fullText"
                                            label={"backoffice.filter.fullText.label"}
                                            helpMessage={intl.formatMessage({
                                                id: "backoffice.users.filter.fullText.helpMessage",
                                            })}
                                            control={useFormMethods.control}
                                            sx={styles.filter.fullText}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}> 
                                        <RhfListOfValuesSelect id="filter-institution"
                                            name="universityId"
                                            group={GROUP.university}
                                            control={useFormMethods.control}
                                            label={"backoffice.users.filter.institution"}
                                            sx={ isMobile ? styles.filter.institution.mobile : 
                                                styles.filter.institution.desktop }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <RhfListOfValuesCombo id="filter-userRoleGroup"
                                            name="roleGroupName"
                                            group={GROUP.userRoleGroupName}
                                            addItemNone={true}
                                            itemNoneLabel= { intl.formatMessage({ id: "lov.none.masc" }) }
                                            control={useFormMethods.control}
                                            label={"backoffice.users.filter.userRoleGroup"}
                                            sx={styles.filter.userRoleGroup}
                                        />
                                    </Grid>
                                   
                                    <Grid item xs={12} sm={6}>
                                        <RhfRadioButtonAllRow id={"filter-enrolled"}
                                            name="isEnrolledInCourse"
                                            control={useFormMethods.control}
                                            label={"backoffice.users.filter.enrolled"}
                                            defaultValue = ""
                                            sx={styles.filter.enrolled}
                                        /> 
                                     </Grid>
                                    
                                    { showCourses &&
                                        <Grid item xs={12} sm={6}>
                                            <RhfAutoCompleteMonoValue id={"filter-course"}
                                                    name={"courseId"}
                                                    options={courses}
                                                    label={"backoffice.users.filter.course"}
                                                    control={useFormMethods.control}
                                                    setValue={useFormMethods.setValue}
                                                    sx={styles.filter.course}
                                                /> 
                                        </Grid>
                                    } 
                                    
                                    <Grid item xs={12} sm={6}> 
                                        <RhfRadioButtonAllRow id={"filter-connected"}
                                            name="isAlreadyConnected"
                                            control={useFormMethods.control}
                                            label={"backoffice.users.filter.connected"}
                                            defaultValue = ""
                                            sx={styles.filter.connected}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <></>     
                                    </Grid>
                                    
                                </Grid>
                
                            </AccordionDetails>
                            <Divider/>
                            <AccordionActions id="user-filter-actions" style={ styles.filter.accordionActions }>
                                <Button color="secondary" onClick={resetForm}>
                                    {intl.formatMessage({id: "backoffice.filter.reset"})}
                                </Button>
                                <Button variant="contained"color="primary" type="submit" disabled={disableSubmit}>
                                    {intl.formatMessage({id: "backoffice.filter.search"})}
                                </Button>
                            </AccordionActions>
                        
                        </Accordion>
                        </form>
                        
                        <Grid item>
                            <CustomDataGrid
                                columns={columns}
                                loading={loading}
                                loadServerRows={loadServerRows}
                                page={page}
                                rows={rows}
                                pageSize={pageSize}
                                totalRows={totalNumberOfRow}
                                changeSelection={handleSelectionChange}
                                selectionModel={selectionModel}
                                editColumnParams={editColumnParams}
                                addMessage="backoffice.datagrid.add"
                                addLink="/back-office/users/add"
                                onDelete={handleDelete}
                                rowDoubleClickLink="/back-office/users/details"

                                // Bouton de l'Export User
                                // showExportButton={showExportButton}
                                // onExport={handleExport}
                                // disabledExport={disabledExport}
                            />    
                        </Grid>
            </LayoutBo>    
        </>
    );

}

export default UserListPage;